import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

export const Item = styled('li')``;

export const List = ({
  className,
  emptyTemplate: Empty,
  items,
  noWrapper,
  options,
  template: Template,
  ...props
}) =>
  items.length === 0 ? (
    <Empty {...options} />
  ) : (
    <ul className={className} {...props}>
      {items.map((item, index) =>
        noWrapper ? (
          <Template
            key={JSON.stringify(item)}
            {...options}
            index={index}
            item={item}
            itemScope
            itemType="http://schema.org/JobPosting"
            {...(typeof item === 'object' && item)}
          />
        ) : (
          <Item key={JSON.stringify(item)}>
            <Template
              {...options}
              index={index}
              item={item}
              itemScope
              itemType="http://schema.org/JobPosting"
              {...(typeof item === 'object' && item)}
            />
          </Item>
        ),
      )}
    </ul>
  );

List.defaultProps = {
  className: '',
  emptyTemplate() {
    return null;
  },
  items: [],
  noWrapper: false,
  options: {},
};

List.propTypes = {
  className: PropTypes.string,
  emptyTemplate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  items: PropTypes.arrayOf(PropTypes.any),
  noWrapper: PropTypes.bool,
  options: PropTypes.objectOf(PropTypes.any),
  template: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

export default styled(List)`
  list-style: none;
  margin: 0;
  padding: 0;
`;
