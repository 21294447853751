import React from 'react';
// eslint-disable-next-line
import { basename } from 'path';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import View from 'Containers/Jobs';

const Jobs = ({
  data: {
    markdown: { edges },
  },
}) => {
  const roles = edges.map(({ node }) => {
    const { fileAbsolutePath: filename, frontmatter } = node;

    return {
      slug: basename(filename, '.md'),
      ...frontmatter,
    };
  });

  return <View roles={roles} />;
};

Jobs.propTypes = {
  data: PropTypes.shape({
    markdown: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};

export const query = graphql`
  {
    markdown: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "role" } } }
      sort: { fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fileAbsolutePath
          frontmatter {
            icon
            title
          }
        }
      }
    }

    metadata: site {
      siteMetadata {
        description
        keywords
        robots
        title
      }
    }
  }
`;

export default Jobs;
