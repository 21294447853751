import React from 'react';
import { rgba } from 'polished';
import PropTypes from 'prop-types';
import styled from '@styled-components';
import { Link } from 'gatsby';

const Caption = styled('figcaption')``;
const Image = styled('img')``;
const Picture = styled('picture')``;
const Title = styled('h4')``;

const Role = ({ className, icon, slug, title }) => (
  <Link to={`/jobs/${slug}`}>
    <figure className={className}>
      <Picture>
        <Image src={icon} />
      </Picture>
      <Caption>
        <Title>{title}</Title>
      </Caption>
    </figure>
  </Link>
);

Role.defaultProps = {
  icon: '/icons/placeholder-image.jpg',
};

Role.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
};

export default styled(Role)`
  background-color: white;
  border-radius: 0.8rem;
  border: 0.1rem solid ${rgba('#000f19', 0.05)};
  box-shadow: 0 8px 16px 0 ${rgba('#000f19', 0.04)},
    0 2px 4px 0 ${rgba('#000f19', 0.08)};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 21.6rem;
  width: 21.6rem;
  overflow: hidden;

  ${Picture} {
    align-content: center;
    align-items: center;
    display: flex;
    height: 22rem;
    justify-content: center;
    width: 100%;

    ${Image} {
      width: 35%;
    }
  }

  ${Caption} {
    align-items: center;
    background-color: #f7f9fc;
    display: flex;
    flex-direction: column;
    min-height: 8rem;
    justify-content: center;
    text-align: center;

    ${Title} {
      color: #000f19;
      font-size: 1.6rem;
      line-height: 1.5;
      opacity: 0.6;
    }
  }
`;
