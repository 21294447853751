import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import List from 'Components/List';

import Header from './Header';
import Role from './Role';

const Container = styled('section')``;
const Wrapper = styled('div')``;

const Jobs = ({ className, roles }) => (
  <Container className={className}>
    <Header />
    <Wrapper>
      <List items={roles} template={Role} />
    </Wrapper>
  </Container>
);

Jobs.propTypes = {
  className: PropTypes.string,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      slug: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
};

export default styled(Jobs)`
  background-image: linear-gradient(#003566, #003566),
    linear-gradient(240deg, #00284d, #003566);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 35rem - 8.2rem);

  ${Wrapper} {
    background: #ffffff;
  }

  ${List} {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 128rem;
    min-height: 43vh;
    padding: 2.4rem;

    ${Role} {
      margin: 1.2rem;
    }
  }
`;
