import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import { withIntl, intlShape } from '@react-intl';

const Title = styled('h1')``;
const Description = styled('p')``;

const Header = ({ className, intl }) => (
  <header className={className}>
    <Title>{intl.formatMessage({ capitalize: true, id: 'JOBS.TITLE' })} </Title>
    <Description>
      {intl.formatMessage({ capitalize: true, id: 'JOBS.DESCRIPTION' })}
    </Description>
  </header>
);

Header.propTypes = {
  className: PropTypes.string,
  intl: intlShape,
};

export default styled(withIntl(Header))`
  text-align: center;
  color: white;
  padding: 3.2rem 0 3.2rem;

  & ${Title} {
    font-size: 2.8rem;
  }
  & ${Description} {
    font-size: 1.6rem;
    line-height: 2rem;
    margin: 2.4rem auto 0;
    max-width: 30rem;
    opacity: 0.6;
  }

  @media screen and (min-width: 600px) {
    padding: 6.4rem 0 5.6rem;

    & ${Title} {
      font-size: 4rem;
    }

    & ${Description} {
      font-size: 2.4rem;
      line-height: 3.2rem;
      margin: 2.4rem auto 0;
      max-width: 70rem;
    }
  }
`;
